import { IonIcon } from "@ionic/react";
import { caretDownOutline, caretForwardOutline, addOutline } from "ionicons/icons";
import { useState } from "react";

import { fN } from "../../functions/baseFunctions";
import FireCloneAPlan from "./FireCloneAPlan";

const FireHeader = ({
  userID,
  getItems,
  setShowFireItems,
  showFireItems,
  theData,
  fireEditActive,
  setFireEditActive,
  setAddItemActive,
  activeFireKey,
  setActiveFireKey,
  fireKey,
  handleFireUpdate,
}) => {
  const [showCloneFirePlan, setShowCloneFirePlan] = useState(false);

  return (
    <div className="fire-item-header">
      <div className="fire-item-header-title">
        <IonIcon icon={activeFireKey === fireKey && showFireItems ? caretDownOutline : caretForwardOutline} style={{ marginBottom: -3 }} />
        <span
          onClick={() => {
            setShowFireItems(!showFireItems);
            setActiveFireKey(fireKey);
          }}
        >
          {theData.name}
        </span>{" "}
        |{" "}
        <span style={{ fontSize: "19px", fontWeight: 500 }}>
          {" "}
          ${theData.fireNumber && fN(theData.fireNumber)}
          <span style={{ fontSize: "12px" }}>/Year</span>
        </span>
        <span>
          <IonIcon
            icon={addOutline}
            style={{ marginBottom: -4, marginRight: "3px", fontSize: "26px", float: "right" }}
            onClick={() => {
              setAddItemActive(true);
              setActiveFireKey(fireKey);
            }}
          />
        </span>
        <div className="fire-item-header-desc">{theData.desc}</div>
      </div>
      <span className={theData.default ? "fire-item-header-tag-active" : "fire-item-header-tag"} onClick={() => handleFireUpdate(fireKey, { default: !theData.default })}>
        default
      </span>
      <span className={theData.fav ? "fire-item-header-tag-active" : "fire-item-header-tag"} onClick={() => handleFireUpdate(fireKey, { fav: !theData.fav })}>
        favorite
      </span>
      <span style={{ marginLeft: "18px" }}></span>
      <span className="fire-item-header-tag">
        Inf. <b>{theData.inflation}</b>
      </span>
      <span className="fire-item-header-tag">
        ROI <b>{theData.investmentROI}</b>
      </span>

      <span
        className="fire-item-header-tag-edit"
        style={{ float: "right" }}
        onClick={() => {
          setActiveFireKey(fireKey);
          setFireEditActive(!fireEditActive);
        }}
      >
        Edit
      </span>

      <span className="fire-item-header-tag-clone" style={{ float: "right" }} onClick={() => setShowCloneFirePlan(true)}>
        Clone
      </span>
      <FireCloneAPlan userID={userID} showCloneFirePlan={showCloneFirePlan} setShowCloneFirePlan={setShowCloneFirePlan} theData={theData} getItems={getItems} />
    </div>
  );
};

export default FireHeader;
