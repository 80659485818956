function adjustDate(date = new Date(), days = 0) {
  const adjustedDate = new Date(date.getTime());
  adjustedDate.setDate(date.getDate() + days);
  return adjustedDate;
}

export const fN = (num) => Math.floor(num).toLocaleString();

export const fNK = (num) => {
  const n = Number(num);
  if (n >= 0 && n <= 1) return n.toFixed(2);
  if (n >= 1e6 || n <= -1e6) return (Math.floor(n) / 1e6).toFixed(3) + "M";
  if (n >= 1e3 || n <= -1e3) return (Math.floor(n) / 1e3).toFixed(2) + "K";
  return Math.floor(n).toLocaleString();
};

export const fNKL = (num) => {
  const n = Number(num);
  if (n >= 0 && n <= 1) return n;
  if (n >= 1e6 || n <= -1e6) return (Math.floor(n) / 1e6).toFixed(3) + "M";
  if (n >= 1e3 || n <= -1e3) return (Math.floor(n) / 1e3).toFixed(1) + "K";
  return Math.floor(n).toLocaleString();
};

export const trunc = (str, num) => (str.length <= num ? str : str.slice(0, num) + "..");

export const dForm = (num) => {
  // Check if num is a valid number and convert it to a string
  if (num && num.toString().length === 8) {
    const str = num.toString();
    return str.slice(0, 4) + "-" + str.slice(4, 6) + "-" + str.slice(6);
  }
  return "--";
};

export const removeTransactionsDuplicates = (data) => {
  // Use a Map to ensure uniqueness by transactionID
  const uniqueEntries = new Map();

  data.forEach((item) => {
    if (item.transactionID) {
      uniqueEntries.set(item.transactionID, item);
    }
  });

  // Convert the Map values back to an array
  return Array.from(uniqueEntries.values());
};

export const prepareChartData = async (data, size = 20, deposits, ytd) => {
  const year = new Date().toLocaleDateString("en-US", { year: "2-digit", timeZone: "America/New_York" });
  const datesArray = [];
  const amountsArray = [];
  const roiArray = [];
  let yearFirst;

  Object.entries(data).forEach(([_, theData]) => {
    const dateItem = new Date(theData.date * 1000).toLocaleString("en-us", { month: "short", day: "numeric", year: "2-digit" });
    if (["Jan 1", "Jan 2", "Jan 3"].some((d) => dateItem.startsWith(d) && dateItem.endsWith(year))) yearFirst = dateItem;

    datesArray.push(dateItem);
    amountsArray.push(theData.amount);
    roiArray.push((Number(theData.amount) / Number(deposits)) * 100 - 100);
  });

  if (ytd && yearFirst) {
    const newArray = datesArray.slice(datesArray.indexOf(yearFirst));
    const newArrayLen = newArray.length;
    return { dates: newArray, amounts: amountsArray.slice(-newArrayLen), rois: roiArray.slice(-newArrayLen) };
  }
  return { dates: datesArray.slice(-size), amounts: amountsArray.slice(-size), rois: roiArray.slice(-size) };
};

export const getMonday = (d) => {
  const date = new Date(d);
  const day = date.getDay();
  const diff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is Sunday
  return new Date(date.setDate(diff));
};

export const getNYSETime = () =>
  new Date().toLocaleString("en-US", { day: "2-digit", month: "short", year: "numeric", hour: "2-digit", minute: "2-digit", timeZone: "America/New_York" });

export const validateArray = (arr) => [...new Set(arr.map((element) => element.trim()))];
