import { useEffect, useState } from "react";

import FireHeaderChart from "./FireHeaderChart";
import { fN } from "../../functions/baseFunctions";

const WithPercent = ({ fireKey, data, rulePercent, fireTotalAmount, switchRulePercent, homeStockData, homeCryptoData, endingCash, includeStockCashAsInvestment, assets }) => {
  const [totalInvestment, setTotalInvestment] = useState(0);

  useEffect(() => {
    if (includeStockCashAsInvestment && homeStockData.stats && homeCryptoData.stats) {
      setTotalInvestment(Number(homeStockData.stats.total) + Number(homeCryptoData.stats.cryptoTotal) + Number(assets));
    } else if (!includeStockCashAsInvestment && homeStockData && homeCryptoData) {
      setTotalInvestment(Number(homeStockData.stats.total - endingCash) + Number(homeCryptoData.stats.cryptoTotal) + Number(assets));
    }
  }, [homeStockData, endingCash, includeStockCashAsInvestment]);

  return (
    <div className="fire-stats">
      <div>
        {/* <IonIcon icon={shieldHalfOutline} /> */}
        <div className="stock-top-stats-title">Amount</div>
        <div className="stock-top-stats-value" style={{ paddingBottom: "10px" }}>
          ${fN(Math.floor(fireTotalAmount / (rulePercent / 100)))}
        </div>
        <div className="stock-top-stats-title">Withdrawal Rate</div>
        <div className="stock-top-stats-value">{rulePercent}%</div>
      </div>
      <div>
        <FireHeaderChart target={Number(((totalInvestment / Math.floor(fireTotalAmount / (rulePercent / 100))) * 100).toFixed(2))} type="main" />
      </div>

      <div>
        {/* <IonIcon icon={buildOutline} /> */}
        <div className="stock-top-stats-title">Total Spending</div>
        <div className="stock-top-stats-value" style={{ paddingBottom: "10px" }}>
          ${fN(data.totalSpendingNumber)}
        </div>
        <div className="stock-top-stats-title">Total Income</div>
        <div className="stock-top-stats-value">${fN(data.totalIncomeNumber)}</div>
      </div>
    </div>
  );
};

export default WithPercent;
