import { Fragment } from "react";

const StockErnDiv = ({ title, data, setFilteredStock }) => {
  return data && data[title] && data[title].length > 0 ? (
    <Fragment>
      <div className="stock-earnings-item">
        <div className="stock-earnings-day-title">
          {title} ({data[title].length})
        </div>
        <div style={{ marginTop: "0px", marginBottom: "4px" }}>
          {data &&
            data[title] &&
            data[title].length > 0 &&
            data[title].map((item, index) => (
              <div key={index} onClick={() => setFilteredStock(item.symbol)} style={{ float: "left", textAlign: "center" }}>
                <span className={`stock-earnings-item-tag-${title}`}>
                  <b>{item.symbol && item.symbol.toUpperCase()}</b>
                </span>

                {title === "today" || title === "tomorrow" ? (
                  <>
                    <br />
                    <span style={{ fontSize: "10px", letterSpacing: -0.6 }}>{item.suppliedDateTime}</span>
                  </>
                ) : (
                  <>
                    <br />
                    <span style={{ fontSize: "10px" }}>{item.suppliedDateTime ? item.suppliedDateTime : item.earningsDate}</span>
                  </>
                )}
              </div>
            ))}
        </div>
      </div>
    </Fragment>
  ) : null;
};

export default StockErnDiv;
