import { Fragment, useEffect, useState } from "react";
import { IonSpinner } from "@ionic/react";
import { IonIcon, useIonAlert } from "@ionic/react";
import { pushRecord, getChildData, editChildData } from "../../functions/funcFirebase";
import { trashOutline, createOutline, addOutline } from "ionicons/icons";

import ProgressBar from "../general/ProgressBar";
import { getCryptoInfo } from "../../functions/funcCrypto";
import { fN, fNK } from "../../functions/baseFunctions";

const CryptoDetails = ({ userID, remoteConfig, privacyMode, setCryptoInfoStats, reloading, addForm, setAddForm, refreshFBData, refreshCryptoData, setRefreshCryptoData }) => {
  const [activeID, setActiveID] = useState("");
  const [form, setForm] = useState({});
  const [formType, setFormType] = useState("");
  const [rawPositions, setRawPositions] = useState([]);
  const [cryptoLiveData, setCryptoLiveData] = useState([]);
  const [present] = useIonAlert();

  const getCrypto = async () => {
    const cryptoRawData = await getChildData(userID, "/crypto");
    setRawPositions(cryptoRawData && cryptoRawData.data && cryptoRawData.data);
    const cryptoInfo = await getCryptoInfo(userID, remoteConfig, cryptoRawData.data);
    setCryptoLiveData(cryptoInfo.crypto);
    setCryptoInfoStats(cryptoInfo.stats);
    setRefreshCryptoData(false);
  };
  const addCrypto = () => {
    const path = "crypto";
    pushRecord(userID, path, form);
    setForm({});
    setFormType("");
    setAddForm(false);
    refreshFBData();
  };

  const editItem = async (id) => {
    setForm({});
    setActiveID(id);
    setFormType("edit");
    const resData = await getChildData(userID, `/crypto/${id}`);
    setForm(resData.data);
  };

  const editCrypto = async () => {
    setFormType("edit");
    editChildData(userID, `crypto/${activeID}`, form);
    setFormType("");
    setForm({});
    // refreshCrypto();
    setAddForm(false);
    refreshFBData();
  };

  const deleteCrypto = async (id) => {
    editChildData(userID, `crypto/${id}`, null);
    refreshFBData();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const cancelForm = () => {
    setFormType("");
    setAddForm(false);
  };

  useEffect(() => {
    if (refreshCryptoData) {
      getCrypto();
      setCryptoLiveData([]);
    }
  }, [refreshCryptoData]);

  useEffect(() => {
    getCrypto();
  }, []);

  return (
    <Fragment>
      {/* // ####################### ADD/EDIT FORM ####################### */}
      <div>
        {(formType === "add" || formType === "edit" || addForm) && (
          <div className="stock-account-form-edit">
            <table className="stock-account-table">
              <tbody>
                <tr>
                  <td style={{ width: "40%" }}>Order:</td>
                  <td style={{ width: "60%" }}>
                    <input type="text" className="stock-account-input" name="order" value={form["order"] || ""} onChange={handleChange} />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "40%" }}>Symbol:</td>
                  <td style={{ width: "60%" }}>
                    <input type="text" className="stock-account-input" name="symbol" value={form["symbol"] || ""} onChange={handleChange} />
                  </td>
                </tr>

                <tr>
                  <td style={{ width: "40%" }}>Coins:</td>
                  <td style={{ width: "60%" }}>
                    <input type="text" className="stock-account-input" name="coins" value={form["coins"] || ""} onChange={handleChange} />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "40%" }}>Invested (USD):</td>
                  <td style={{ width: "60%" }}>
                    <input type="text" className="stock-account-input" name="invested" value={form["invested"] || ""} onChange={handleChange} />
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "40%" }}>Broker:</td>
                  <td style={{ width: "60%" }}>
                    <input type="text" className="stock-account-input" name="broker" value={form["broker"] || ""} onChange={handleChange} />
                  </td>
                </tr>

                {form.staking && (
                  <tr>
                    <td style={{ width: "40%" }}>Staking Rate %:</td>
                    <td style={{ width: "60%" }}>
                      <input type="text" className="stock-account-input" name="stakingRate" value={form["stakingRate"] || ""} onChange={handleChange} />
                    </td>
                  </tr>
                )}
                <tr>
                  <td style={{ width: "40%" }}>Options:</td>
                  <td style={{ width: "60%" }}>
                    {form.fav ? (
                      <span className="stock-account-tag" onClick={() => setForm({ ...form, fav: false })}>
                        Favorite
                      </span>
                    ) : (
                      <span className="stock-account-tag-off" onClick={() => setForm({ ...form, fav: true })}>
                        Favorite
                      </span>
                    )}

                    {form.staking ? (
                      <span className="stock-account-tag" onClick={() => setForm({ ...form, staking: false })}>
                        Staking
                      </span>
                    ) : (
                      <span className="stock-account-tag-off" onClick={() => setForm({ ...form, staking: true })}>
                        Staking
                      </span>
                    )}

                    {form.countAsInvestment ? (
                      <span className="stock-account-tag" onClick={() => setForm({ ...form, countAsInvestment: false })}>
                        Investment
                      </span>
                    ) : (
                      <span className="stock-account-tag-off" onClick={() => setForm({ ...form, countAsInvestment: true })}>
                        Investment
                      </span>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>

            <p />

            {(formType === "add" || addForm) && (
              <div className="bottons-divider">
                <div onClick={() => addCrypto()} className="general-button-small">
                  Add
                </div>
                <div onClick={() => cancelForm()} className="general-button-small-cancel">
                  Cancel
                </div>
              </div>
            )}
            {formType === "edit" && (
              <div className="bottons-divider">
                <div onClick={() => editCrypto()} className="general-button-small">
                  Save
                </div>
                <div onClick={() => cancelForm()} className="general-button-small-cancel">
                  Cancel
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {cryptoLiveData && cryptoLiveData.length > 0 ? (
        cryptoLiveData
          .sort((a, b) => (a.order > b.order ? 1 : -1))
          .map((item, index) => (
            <Fragment key={index}>
              <div>
                <div className="crypto-wrapper">
                  <div>
                    <div className="crypto-title">
                      {item.symbol && item.symbol.toUpperCase()}
                      <div className="crypto-name">{item.shortName}</div>
                      <div className="crypto-price">${item.last && item.last > 1000 ? fN(Math.floor(item.last)) : item.last < 1 ? item.last.toFixed(4) : item.last.toFixed(2)}</div>
                      <div style={{ fontSize: "10px" }}>
                        (avg: <b>${fNK(Number((item.invested / item.coins).toFixed(6)))}</b>)
                      </div>
                      <div className="crypto-24h">
                        {item.change >= 0 ? (
                          <>
                            <span style={{ color: "green" }}>+{item.change.toFixed(2)}</span>
                            <span style={{ marginLeft: "6px", marginRight: "6px" }}>|</span>
                            <span style={{ color: "green" }}>+{item.changePercent.toFixed(2)}%</span>
                          </>
                        ) : (
                          <>
                            <span style={{ color: "red" }}>{item.change.toFixed(2)}</span>
                            <span style={{ marginLeft: "6px", marginRight: "6px" }}>|</span>
                            <span style={{ color: "red" }}>{item.changePercent.toFixed(2)}%</span>
                          </>
                        )}{" "}
                      </div>
                      {/* <div className="crypto-fiftyTwoWeekRange">
                        {fNK(item.yearLow)} - {fNK(item.yearHigh)}
                      </div> */}
                      <ProgressBar minValue={item.yearLow} maxValue={item.yearHigh} currentValue={item.last} />
                    </div>
                  </div>

                  <div className="crypto-info-wrapper">
                    <div className="crypto-info">
                      <div className="crypto-info-item2">Coins: </div>
                      <div className="crypto-info-item">
                        {privacyMode ? "*****" : Number(item.coins) > 1000 ? fN(Math.floor(Number(item.coins))) : Number(item.coins).toFixed(6)}
                      </div>

                      <div className="crypto-info-item2">Value: </div>

                      <div className="crypto-info-item2">
                        <b>${privacyMode ? "*****" : fN(item.value)}</b>
                      </div>

                      <div className="crypto-info-item2">Invested: </div>
                      <div className="crypto-info-item">${privacyMode ? "*****" : fN(Math.floor(item.invested))}</div>

                      <div className="crypto-info-item2">Profits: </div>
                      <div className="crypto-info-item" style={item.profits > 0 ? { color: "green" } : { color: "red" }}>
                        <b>${privacyMode ? "*****" : fN(Math.floor(item.profits))}</b>
                      </div>

                      {item.stakingRate && (
                        <>
                          <div className="crypto-info-item2">Staking: </div>
                          <div className="crypto-info-item">
                            ${privacyMode ? "*****" : fN(Math.floor((item.value * item.stakingRate) / 100))}/y | $
                            {privacyMode ? "*****" : fN(Math.floor((item.value * item.stakingRate) / 100 / 12))}/m
                          </div>
                        </>
                      )}
                    </div>

                    <div className="crypto-profit-percent">
                      <div className="crypto-roi">ROI</div>
                      {privacyMode ? "**" : Math.floor((item.profits / item.invested) * 100)}
                      <span style={{ fontSize: "16px", color: "darkgray" }}>%</span>
                      {item.staking && <div className="crypto-staking">Staking {item.stakingRate && "@ " + item.stakingRate + "%"}</div>}
                      <div className="crypto-broker">{item.broker}</div>
                      <IonIcon icon={createOutline} onClick={() => editItem(item.fbID)} style={{ paddingRight: "4px", color: "darkblue", fontSize: "18px" }} />
                      <IonIcon
                        icon={trashOutline}
                        onClick={() =>
                          present({
                            cssClass: "my-css",
                            header: "Delete",
                            message: "Are you sure you want to delete this item?",
                            buttons: ["Cancel", { text: "Delete", handler: (d) => deleteCrypto(item.fbID) }],
                          })
                        }
                        style={{ paddingLeft: "4px", color: "darkred", fontSize: "18px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          ))
      ) : (
        <div style={{ justifyContent: "center", display: "flex" }}>
          <IonSpinner name="lines" />
        </div>
      )}

      {rawPositions === null && (
        <div style={{ justifyContent: "center", display: "flex", fontSize: "12px", padding: "5px", margin: "5px" }}>
          {formType === "" && (
            <div className="general-button-small" onClick={() => setFormType("add")}>
              <IonIcon icon={addOutline} style={{ paddingRight: "4px", marginBottom: "-5px", color: "yellow", fontSize: "20px" }} />
              Add Position
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default CryptoDetails;
