import axios from "axios";
import { getMonday, fNK } from "./baseFunctions";

const handleStockMarketState = (stock) => {
  let last = 0,
    change = 0,
    changePercent = 0;

  switch (stock.marketState) {
    case "PREPRE":
      last = stock.postMarketPrice || stock.regularMarketPrice;
      change = stock.postMarketChange || 0;
      changePercent = stock.postMarketChangePercent || 0;
      break;
    case "PRE":
      last = stock.preMarketPrice || stock.regularMarketPrice;
      change = stock.preMarketChange || 0;
      changePercent = stock.preMarketChangePercent || 0;
      break;
    case "REGULAR":
      last = stock.regularMarketPrice || 0;
      change = stock.regularMarketChange || 0;
      changePercent = stock.regularMarketChangePercent || 0;
      break;
    case "CLOSED":
      last = stock.regularMarketPrice || 0;
      change = stock.regularMarketChange || 0;
      changePercent = stock.regularMarketChangePercent || 0;
      break;
    case "POST":
      last = stock.postMarketPrice || stock.regularMarketPrice;
      change = (stock.postMarketChange || 0) + stock.regularMarketChange;
      changePercent = (stock.postMarketChangePercent || 0) + stock.regularMarketChangePercent;
      break;
    case "POSTPOST":
      last = stock.postMarketPrice || stock.regularMarketPrice;
      change = (stock.postMarketChange || 0) + stock.regularMarketChange;
      changePercent = (stock.postMarketChangePercent || 0) + stock.regularMarketChangePercent;
      break;
    default:
      last = 0;
      change = 0;
      changePercent = 0;
  }

  return { last, change, changePercent };
};

export const calcStockGroup = async (groupStocks, netLiq) => {
  return groupStocks.reduce((acc, item) => acc + item.value, 0);
};

export const getDefaultStockAccount = (accounts) => {
  let defaultAccountID;
  let defaultAccountData;
  for (const [key, value] of Object.entries(accounts)) {
    if (value.default) {
      defaultAccountID = key;
      defaultAccountData = value;
      break;
    }
  }
  return { id: defaultAccountID, data: defaultAccountData };
};

const getStockData = async (remoteConfig, symbols, userID, marketSummary = false) => {
  const url = `${remoteConfig.stockURL}/stock?sym=${symbols}&appToken=${userID}${marketSummary ? "&market=true" : ""}`;
  try {
    return await axios(url);
  } catch (e) {
    console.log("Error getting Live Stock: ", e.message);
  }
};

export const getStockInfo = async (userID, remoteConfig, rawPositions, accountType, deposits, endingCash) => {
  const stockInfoArray = [];
  const stockInfoStats = {
    total: Number(endingCash),
    deposits,
    costBasis: 0,
    profits: 0,
    numberOfStocks: 0,
    dailyPNL: 0,
    stocksROI: 0,
    endingCash,
  };
  const symbols = [];
  const positions = [];

  rawPositions.forEach((item) => {
    symbols.push(item.symbol);
    positions.push({
      fbID: item.fbID,
      symbol: item.symbol,
      position: item.position,
      costBasis: Number(item.costBasisPrice),
      desc: item.description,
    });
  });

  const stockData = await getStockData(remoteConfig, symbols, userID);

  positions.forEach((item) => {
    const stock = stockData.data.stocks.find((data) => data.symbol === item.symbol.toUpperCase());
    if (stock) {
      const { last, change, changePercent } = handleStockMarketState(stock);

      stockInfoArray.push({
        ...item,
        last,
        change,
        changePercent,
        open: stock.regularMarketOpen,
        close: stock.regularMarketPreviousClose,
        high: stock.regularMarketDayHigh,
        low: stock.regularMarketDayLow,
        week_52_high: stock.fiftyTwoWeekHigh,
        week_52_low: stock.fiftyTwoWeekLow,
        description: stock.longName,
        type: stock.quoteType,
        forwardPE: stock.forwardPE || 0,
        preMarketPrice: stock.preMarketPrice || 0,
        preMarketChange: stock.preMarketChange || 0,
        preMarketChangePercent: stock.preMarketChangePercent || 0,
        marketState: stock.marketState,
      });
      stockInfoStats.total += item.position * last;
      stockInfoStats.numberOfStocks += 1;
      stockInfoStats.costBasis += item.position * item.costBasis;
      stockInfoStats.dailyPNL += item.position * change;
    }
  });

  stockInfoStats.profits = deposits - stockInfoStats.costBasis;
  stockInfoStats.stocksROI = ((stockInfoStats.total / deposits) * 100 - 100).toFixed(1);

  return { stocks: stockInfoArray, stats: stockInfoStats };
};

export const getIBKRStockInfo = async (userID, remoteConfig, rawPositions, accountType, deposits, endingCash, stockWatchList) => {
  const stockInfoArray = [];
  const stockWatchInfoArray = [];
  const stockInfoStats = {
    total: Number(endingCash),
    deposits: Number(deposits),
    costBasis: 0,
    profits: 0,
    numberOfStocks: 0,
    dailyPNL: 0,
    stocksROI: 0,
    totalPNL: 0,
    totalMktValue: 0,
    totalUnrealized: 0,
    totalLoss: 0,
  };

  // Amending the irregular stock names
  const index = rawPositions.findIndex((item) => item.symbol.toUpperCase() === "BRK B");
  if (rawPositions[index] !== undefined) {
    rawPositions[index].symbol = "BRK-B";
  }

  const symbols = rawPositions.map((item) => item.symbol);
  const positions = rawPositions;
  const symbolsWatchSymbols = Object.values(stockWatchList).map((item) => item.symbol);
  const stockWatchInfoArrayTemp = Object.entries(stockWatchList).map(([_, data]) => ({
    symbol: data.symbol,
    watchPrice: Number(data.watchPrice),
    priority: data.priority,
    type: data.type,
  }));
  const streamData = {
    "^GSPC": {
      last: 0,
      change: 0,
      changePercent: 0,
    },
    "^DJI": {
      last: 0,
      change: 0,
      changePercent: 0,
    },
    "^IXIC": {
      last: 0,
      change: 0,
      changePercent: 0,
    },
  };
  let stockEarnings = {
    activate: false,
    today: [],
    tomorrow: [],
    yesterday: [],
    thisWeek: [],
    thisMonth: [],
    nextWeek: [],
    nextMonth: [],
  };
  let stockDividends = {
    activate: false,
    today: [],
    tomorrow: [],
    yesterday: [],
    thisWeek: [],
    thisMonth: [],
    nextWeek: [],
  };

  const updateStockEarnings = (data) => {
    // Convert UNIX timestamp to JavaScript Date in New York timezone
    // const earningsDate = new Date(data.earningsTimestampStart * 1000);
    // const nyTimezoneOffset = -5 * 60 * 60 * 1000; // Offset in milliseconds for EST (UTC-5)
    const nyTimezoneOffset = -5 * 60 * 60 * 1000; // For EST only (not DST)
    const earningsDate = new Date(data.earningsTimestampStart * 1000);
    const now = new Date();

    // Helper function to convert a date to New York time
    const convertToNYTime = (date) => {
      // This shifts a UTC date/time by -5 hours:
      return new Date(date.getTime() + nyTimezoneOffset);
    };
    const earningsDateNY = convertToNYTime(earningsDate);
    const currentTimeNY = convertToNYTime(now);

    // const now = new Date();

    // Helper function to convert a date to New York time
    // const convertToNYTime = (date) => new Date(date.getTime() + date.getTimezoneOffset() * 60000 + nyTimezoneOffset);

    // const currentTimeNY = convertToNYTime(now);
    // const earningsDateNY = convertToNYTime(earningsDate);

    // Helper functions for date comparison
    const isSameDay = (date1, date2) => date1.toDateString() === date2.toDateString();
    const getDayOfWeek = (date) => date.toLocaleDateString("en-US", { weekday: "short" });
    const formatMonthDay = (date) => `${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

    // Calculate ranges for comparison (considering Monday as the start of the week)
    const startOfWeek = new Date(currentTimeNY);
    startOfWeek.setDate(currentTimeNY.getDate() - ((currentTimeNY.getDay() + 6) % 7)); // Last Monday

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 4); // Friday of the current week

    const nextWeekStart = new Date(endOfWeek);
    nextWeekStart.setDate(endOfWeek.getDate() + 3); // Next Monday

    const nextWeekEnd = new Date(nextWeekStart);
    nextWeekEnd.setDate(nextWeekStart.getDate() + 4); // Next Friday

    const endOfMonth = new Date(currentTimeNY);
    endOfMonth.setMonth(currentTimeNY.getMonth() + 1, 0); // Last day of the current month

    const startOfNextMonth = new Date(endOfMonth);
    startOfNextMonth.setDate(1); // First day of the next month
    startOfNextMonth.setMonth(endOfMonth.getMonth() + 1);

    const endOfNextMonth = new Date(startOfNextMonth);
    endOfNextMonth.setMonth(startOfNextMonth.getMonth() + 1, 0); // Last day of the next month

    // Ensure the week ranges are calculated consistently in New York time
    if (isSameDay(earningsDateNY, currentTimeNY)) {
      stockEarnings.today.push({
        symbol: data.symbol,
        date: earningsDateNY,
        suppliedDateTime: getDayOfWeek(earningsDateNY),
      });
    } else if (isSameDay(earningsDateNY, new Date(currentTimeNY.getTime() - 86400000))) {
      stockEarnings.yesterday.push({
        symbol: data.symbol,
        date: earningsDateNY,
        suppliedDateTime: getDayOfWeek(earningsDateNY),
      });
    } else if (isSameDay(earningsDateNY, new Date(currentTimeNY.getTime() + 86400000))) {
      stockEarnings.tomorrow.push({
        symbol: data.symbol,
        date: earningsDateNY,
        suppliedDateTime: getDayOfWeek(earningsDateNY),
      });
    } else if (earningsDateNY >= startOfWeek && earningsDateNY <= endOfWeek) {
      stockEarnings.thisWeek.push({
        symbol: data.symbol,
        date: earningsDateNY,
        suppliedDateTime: getDayOfWeek(earningsDateNY),
      });
    } else if (earningsDateNY >= nextWeekStart && earningsDateNY <= nextWeekEnd) {
      stockEarnings.nextWeek.push({
        symbol: data.symbol,
        date: earningsDateNY,
        suppliedDateTime: getDayOfWeek(earningsDateNY),
      });
    } else if (earningsDateNY > nextWeekEnd && earningsDateNY <= endOfMonth) {
      stockEarnings.thisMonth.push({
        symbol: data.symbol,
        date: earningsDateNY,
        suppliedDateTime: formatMonthDay(earningsDateNY),
      });
    } else if (earningsDateNY >= startOfNextMonth && earningsDateNY <= endOfNextMonth) {
      stockEarnings.nextMonth.push({
        symbol: data.symbol,
        date: earningsDateNY,
        suppliedDateTime: formatMonthDay(earningsDateNY),
      });
    }

    // Sort all arrays by date
    const sortByDate = (a, b) => new Date(a.date) - new Date(b.date);

    stockEarnings.yesterday.sort(sortByDate);
    stockEarnings.today.sort(sortByDate);
    stockEarnings.tomorrow.sort(sortByDate);
    stockEarnings.thisWeek.sort(sortByDate);
    stockEarnings.nextWeek.sort(sortByDate);
    stockEarnings.thisMonth.sort(sortByDate);
    stockEarnings.nextMonth.sort(sortByDate);

    stockEarnings.activate = true; // Set activate to true if any data is updated
  };

  const [stockData, stockWatchData] = await Promise.all([getStockData(remoteConfig, symbols, userID, true), getStockData(remoteConfig, symbolsWatchSymbols, userID)]);

  stockWatchInfoArrayTemp.forEach((item) => {
    const stock = stockWatchData.data.stocks.find((data) => data.symbol === item.symbol.toUpperCase());
    if (stock) {
      // console.log("STOCK: ", stock);
      const { last, change, changePercent } = handleStockMarketState(stock);

      const alertType = last <= item.watchPrice && item.type === "BUY" ? "BUY" : last >= item.watchPrice && item.type === "SELL" ? "SELL" : "z";
      const color = alertType === "BUY" ? "red" : alertType === "SELL" ? "green" : "grey";
      stockWatchInfoArray.push({
        symbol: item.symbol,
        last,
        change,
        changePercent,
        watchPrice: item.watchPrice,
        dropPercent: (last / item.watchPrice) * 100 - 100,
        upPercent: (item.watchPrice / last) * 100 - 100,
        priority: item.priority,
        type: item.type,
        alertType,
        color,
      });
    }
  });

  positions.forEach((item) => {
    const stock = stockData.data.stocks.find((data) => data.symbol === item.symbol.toUpperCase());
    if (stock) {
      updateStockEarnings(stock);

      const { last, change, changePercent } = handleStockMarketState(stock);

      // const earnings = processDate(stock.earningsTimestamp);
      // const dividends = processDate(stock.dividendDate);
      streamData[item.symbol] = { last: Number(last), change: Number(change), changePercent: Number(changePercent) };

      stockInfoArray.push({
        last,
        change,
        changePercent,
        symbol: item.symbol,
        position: Number(item.position),
        avgPrice: Number(item.costBasisPrice),
        open: stock.regularMarketOpen,
        close: stock.regularMarketPreviousClose,
        high: stock.regularMarketDayHigh,
        low: stock.regularMarketDayLow,
        week_52_high: stock.fiftyTwoWeekHigh,
        week_52_low: stock.fiftyTwoWeekLow,
        averageAnalystRating: stock.averageAnalystRating,
        forwardPE: stock.forwardPE || 0,
        company: stock.longName,
        type: stock.quoteType,
        pnl: Math.floor(change * item.position),
        unrealized_pnl: Math.floor(Number(last * item.position - item.costBasisPrice * item.position)),
        unrealized_percent: ((Number(last) * item.position - item.costBasisPrice * item.position) / (item.costBasisPrice * item.position)) * 100,
        roi: (((Number(last) * item.position - item.costBasisPrice * item.position) / (item.costBasisPrice * item.position)) * 100).toFixed(2),
        preMarketPrice: stock.preMarketPrice || 0,
        preMarketChange: stock.preMarketChange || 0,
        preMarketChangePercent: stock.preMarketChangePercent || 0,
        marketState: stock.marketState,
        marketValue: item.position * Number(last),
        assetCategory: item.assetCategory,
        description: item.description,
        percentOfNAV: Number(item.percentOfNAV),
        markPrice: Number(item.markPrice) || 0,
        // earningsToday: earnings.suppliedDateDayIsToday,
        // dividendsToday: dividends.suppliedDateDayIsToday,
        // dividendDate: dividends.suppliedDateNYC || "-",
        dividendDate: "-",
        dividendPercent: stock.trailingAnnualDividendYield ? (stock.trailingAnnualDividendYield * 100).toFixed(2) : 0,
        dividendTotal: stock.trailingAnnualDividendRate ? Math.floor(stock.trailingAnnualDividendRate * item.position) : 0,
      });

      stockInfoStats.total += item.position * last;
      stockInfoStats.numberOfStocks += 1;
      stockInfoStats.costBasis += Number(item.position) * Number(item.costBasisPrice);
      stockInfoStats.dailyPNL += item.position * change;
      stockInfoStats.totalPNL += change * item.position;
      stockInfoStats.totalMktValue += Number(last * item.position);
      stockInfoStats.totalUnrealized += Number(last) * item.position - item.costBasisPrice * item.position;

      if (Number(last * item.position - item.costBasisPrice * item.position) < 0) {
        stockInfoStats.totalLoss += Number(last * item.position - item.costBasisPrice * item.position);
      }
    }
  });
  stockInfoStats.stocksROI = ((stockInfoStats.total / Number(deposits)) * 100 - 100).toFixed(2);

  console.log("stockEarnings: ", stockEarnings);
  return {
    stocks: stockInfoArray,
    stockWatch: stockWatchInfoArray,
    marketSummary: stockData.data.marketSummary,
    symbols,
    streamData,
    stockEarnings,
    stockDividends,
    stats: stockInfoStats,
  };
};

export const calcTrades = (trades, accountData) => {
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const monday = getMonday(today).getDate();
  const tradesWTD = trades.filter((item) => {
    const tradeDate = String(item.tradeDate); // Ensure tradeDate is a string
    return Number(tradeDate.slice(6, 8)) >= monday && Number(tradeDate.slice(6, 8)) <= monday + 4;
  });

  const tradesAmountWTD = tradesWTD.reduce((acc, item) => acc + Number(item.cost), 0);
  const tradesAmountMTD = trades
    .filter((item) => {
      const tradeDate = String(item.tradeDate); // Ensure tradeDate is a string
      return tradeDate.slice(0, 6) === `${year}${month}`;
    })
    .reduce((acc, item) => acc + Number(item.cost), 0);

  const tradesAmountYTD = trades
    .filter((item) => {
      const tradeDate = String(item.tradeDate); // Ensure tradeDate is a string
      return tradeDate.slice(0, 4) === `${year}`;
    })
    .reduce((acc, item) => acc + Number(item.cost), 0);

  return {
    accountName: accountData.name,
    accountType: accountData.type,
    deposits: fNK(accountData.deposits),
    stockTradesMonthlyBudget: accountData.stockTradesMonthlyBudget,
    tradesAmountWTD: fNK(tradesAmountWTD),
    tradesAmountMTD: tradesAmountMTD,
    tradesAmountYTD: fNK(tradesAmountYTD),
    tradesNumberWTD: tradesWTD.length,
    tradesNumberMTD: trades.filter((item) => {
      const tradeDate = String(item.tradeDate); // Ensure tradeDate is a string
      return tradeDate.slice(0, 6) === `${year}${month}`;
    }).length,
    tradesNumberYTD: trades.filter((item) => {
      const tradeDate = String(item.tradeDate); // Ensure tradeDate is a string
      return tradeDate.slice(0, 4) === `${year}`;
    }).length,
    tradesWTD,
  };
};

export const getBLSData = async (token, range = -18) => {
  try {
    const response = await axios(`https://api.navscape.io/blsdata?appToken=${token}`);
    const blsData = response.data.Results.series;

    const parseData = (series) =>
      series.data.reduce(
        (acc, item) => {
          acc.dates.push(`${item.periodName.slice(0, 3)} ${item.year}`);
          acc.values.push(item.calculations.pct_changes["12"]);
          return acc;
        },
        { dates: [], values: [] }
      );

    const cpiData = parseData(blsData[0]);
    const ppiData = parseData(blsData[1]);
    const joltsData = parseData(blsData[2]);

    return {
      datescpi: cpiData.dates.reverse().slice(range),
      cpi: cpiData.values.reverse().slice(range),
      datesppi: ppiData.dates.reverse().slice(range),
      ppi: ppiData.values.reverse().slice(range),
      datesjolts: joltsData.dates.reverse().slice(range),
      jolts: joltsData.values.reverse().slice(range),
    };
  } catch (e) {
    console.log("BLS DATA Error: ", e);
  }
};

export const getStocksNAV = (data, topN) => {
  // Extract the required fields: symbol and percentOfNAV
  const stocks = data
    .filter((stock) => stock.percentOfNAV > 0) // Ensure valid percentOfNAV
    .map((stock) => ({
      label: `${stock.symbol} - ${stock.percentOfNAV}%`,
      percentage: stock.percentOfNAV,
    }));

  // Sort stocks by percentage in descending order
  stocks.sort((a, b) => b.percentage - a.percentage);

  // Get the top N stocks
  const topStocks = stocks.slice(0, topN);

  // Calculate the 'Others' percentage
  const topSum = topStocks.reduce((sum, stock) => sum + stock.percentage, 0);
  const othersPercentage = 100 - topSum;

  // Add 'Others' to the result if needed
  if (topN < stocks.length) {
    topStocks.push({
      label: `Others - ${othersPercentage.toFixed(2)}%`,
      percentage: othersPercentage,
    });
  }

  // Prepare the result in the required format
  const result = {
    labels: topStocks.map((stock) => stock.label),
    series: topStocks.map((stock) => stock.percentage),
  };

  return result;
};
