import { Fragment, useEffect } from "react";

import StockErnDiv from "./StockEarnDiv";

const StockEarnings = ({ stockEarningsData, setFilteredStock }) => {
  useEffect(() => {
    console.log("stockEarningsData: ", stockEarningsData);
  }, [stockEarningsData]);
  return (
    <Fragment>
      <div className="stock-watch-list-title">{stockEarningsData.activate && "Earnings"}</div>
      <div className="stock-earnings-wrapper">
        <div className="stock-earnings-div">
          <StockErnDiv title={"yesterday"} data={stockEarningsData} setFilteredStock={setFilteredStock} />
          <StockErnDiv title={"today"} data={stockEarningsData} setFilteredStock={setFilteredStock} />
          <StockErnDiv title={"tomorrow"} data={stockEarningsData} setFilteredStock={setFilteredStock} />
          <StockErnDiv title={"thisWeek"} data={stockEarningsData} setFilteredStock={setFilteredStock} />
          <StockErnDiv title={"nextWeek"} data={stockEarningsData} setFilteredStock={setFilteredStock} />
          <StockErnDiv title={"thisMonth"} data={stockEarningsData} setFilteredStock={setFilteredStock} />
          <StockErnDiv title={"nextMonth"} data={stockEarningsData} setFilteredStock={setFilteredStock} />
        </div>
      </div>
    </Fragment>
  );
};

export default StockEarnings;
