import { Fragment } from "react";

import { trunc, fNK } from "../../functions/baseFunctions";

const StocksTableBody = ({ item, panelOpenHandler, stockSettings, privacyMode }) => {
  return (
    <Fragment>
      <tr className="stock-details-item" onClick={() => panelOpenHandler(item.symbol)}>
        <td style={{ textAlign: "left" }} className="stock-details-item-symbol">
          <span style={{ fontWeight: 500 }}>{item.symbol && item.symbol.slice(0, 8)}</span> {item.earningsToday && <span className="stock-earnings-tag">E</span>}{" "}
          {item.dividendsToday && <span className="stock-dividend-tag">D</span>}
          <div className="stocks-table-company">{item.company && trunc(item.company, 10)}</div>
        </td>

        {stockSettings.last && (
          <td className="stock-table-value-center">
            {item.last.toFixed(2)}
            {stockSettings.mode === "wide" && (
              <div className="stock-wide-info-narrow">
                <b>D:</b> {fNK(item.low)}-{fNK(item.high)}
              </div>
            )}
          </td>
        )}

        {stockSettings.avgPrice && (
          <td className="stock-table-value-center">
            {item.avgPrice.toFixed(2)}
            {stockSettings.mode === "wide" && (
              <div className="stock-wide-info-narrow">
                {/* <b>Y:</b> {fNK(item.yearRange.low)}-{fNK(item.yearRange.high)} */}
                <b>Y:</b> {fNK(item.week_52_low)}-{fNK(item.week_52_high)}
              </div>
            )}
          </td>
        )}

        {stockSettings.change && (
          <td className="stock-table-value-center">
            <span style={item.change >= 0 ? { color: "green" } : { color: "red" }}>{item.change && Number(item.change).toFixed(2)}</span>
          </td>
        )}

        {stockSettings.changePercent && (
          <td className="stock-table-value-center">
            <span style={item.changePercent >= 0 ? { color: "green" } : { color: "red" }}>{item.changePercent.toFixed(2)}%</span>
          </td>
        )}

        {stockSettings.position && <td style={{ textAlign: "center" }}>{privacyMode ? "***" : fNK(item.position)}</td>}

        {stockSettings.averageAnalystRating && (
          <td className="stock-table-value-center">
            <div className="stock-wide-info-narrow-bold" style={{ color: item.ratingColor }}>
              {item.ratingString && trunc(item.ratingString, 6)}
            </div>
            <div className="stock-wide-info-narrow">{item.ratingNumber}</div>
          </td>
        )}

        {stockSettings.dividendPercent && <td style={{ textAlign: "center" }}>{privacyMode ? "***" : item.dividendPercent}%</td>}

        {stockSettings.dividendTotal && <td style={{ textAlign: "center" }}>${privacyMode ? "***" : item.dividendTotal}</td>}

        {stockSettings.dividendDate && <td style={{ textAlign: "center" }}>{item.dividendDate}</td>}

        {stockSettings.forwardPE && <td style={{ textAlign: "center" }}>{item.forwardPE && item.forwardPE.toFixed(2)}</td>}

        {stockSettings.unrealized_percent && (
          <td className="stock-table-value-center">
            {privacyMode ? "***" : <span style={item.unrealized_percent >= 0 ? { color: "green" } : { color: "red" }}>{item.unrealized_percent.toFixed(2)}%</span>}
          </td>
        )}

        {stockSettings.unrealized_pnl && (
          <td className="stock-table-value-center">
            {privacyMode ? "***" : <span style={item.unrealized_pnl >= 0 ? { color: "green" } : { color: "red" }}>{fNK(item.unrealized_pnl)}</span>}
          </td>
        )}

        {stockSettings.percentOfNAV && <td style={{ textAlign: "center" }}>{privacyMode ? "***" : item.percentOfNAV}%</td>}

        {stockSettings.marketValue && (
          <td className="stock-table-value-center">
            <span style={{ fontWeight: 500 }}>{privacyMode ? "***" : fNK(item.marketValue)}</span>
            {stockSettings.mode === "wide" && <div className="stock-wide-info">{fNK(Math.floor(item.avgPrice * item.position))}</div>}
          </td>
        )}

        {stockSettings.pnl && (
          <td style={{ textAlign: "right" }} className="stock-details-item-pnl">
            {privacyMode ? "***" : <span style={item.pnl >= 0 ? { color: "green" } : { color: "red" }}>{fNK(item.pnl)}</span>}
          </td>
        )}
      </tr>
    </Fragment>
  );
};

export default StocksTableBody;
