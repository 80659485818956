import { IonCol, IonContent, IonPage, IonRow, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle } from "@ionic/react";

import { dForm, removeTransactionsDuplicates } from "../../functions/baseFunctions";
import { useEffect, useState } from "react";

const StockTransactions = ({ data, filteredStock, count }) => {
  const [modData, setModData] = useState([]);

  useEffect(() => {
    setModData(removeTransactionsDuplicates(data));
  }, [data]);

  const getMonthKey = (dateNum) => dateNum.toString().slice(0, 6); // Extract YYYYMM from numeric settleDate

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/stocks" />
          </IonButtons>
          <IonTitle>Transactions</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonRow>
          <IonCol size="12">
            <div style={{ textAlign: "center" }}>
              <table className="stock-main-table">
                <thead>
                  <tr className="stock-trades-header">
                    <th>Stock</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {modData &&
                    modData.length > 0 &&
                    (() => {
                      // Sort descending by settleDate
                      const sortedData = modData.sort((a, b) => b.settleDate - a.settleDate);

                      // Group by month
                      const groupedData = sortedData
                        .filter((val) => {
                          if (filteredStock === "") return true;
                          return val.symbol.includes(filteredStock);
                        })
                        .slice(0, count)
                        .reduce((acc, item) => {
                          const monthKey = getMonthKey(item.settleDate);
                          if (!acc[monthKey]) acc[monthKey] = [];
                          acc[monthKey].push(item);
                          return acc;
                        }, {});

                      // Reverse the order of grouped months to display newest first
                      const rows = [];
                      Object.keys(groupedData)
                        .sort((a, b) => b - a) // Ensure month keys are sorted in descending order
                        .forEach((monthKey, index) => {
                          const isGrey = index % 2 === 0;
                          groupedData[monthKey].forEach((item) => {
                            rows.push(
                              <tr
                                key={item.transactionID}
                                className="stock-trades-item"
                                style={{
                                  backgroundColor: isGrey ? "#ffffff" : "#f0f0f0",
                                }}
                              >
                                <td style={{ textAlign: "left" }}>
                                  <b>{item.symbol || "--"}</b>
                                </td>
                                <td>{item.type}</td>
                                <td style={Number(item.amount) > 0 ? { textAlign: "left", color: "green" } : { textAlign: "left", color: "red" }}>
                                  $<b>{item.amount}</b>
                                </td>
                                <td style={{ textAlign: "left" }}>{dForm(item.settleDate)}</td>
                              </tr>
                            );
                          });
                        });
                      return rows;
                    })()}
                </tbody>
              </table>
            </div>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default StockTransactions;
