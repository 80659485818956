import "./ProgressBar.css";
import { fNK } from "../../functions/baseFunctions";

const ProgressBar = ({ minValue, maxValue, currentValue }) => {
  const progress = ((currentValue - minValue) / (maxValue - minValue)) * 100;

  return (
    <div className="progress-container">
      <div className="progress-bar">
        <div className="progress-fill" style={{ width: `${progress}%` }}></div>
      </div>
      {/* <div className="progress-indicator" style={{ left: `${progress}%` }}>
        ${currentValue}
      </div> */}
      <div className="progress-labels">
        <span>${fNK(minValue)}</span>
        <span>${fNK(maxValue)}</span>
      </div>
    </div>
  );
};

export default ProgressBar;
