import { useSelector, useDispatch } from "react-redux";
import { useState, Fragment, useEffect } from "react";

import { IonCol, IonContent, IonIcon, IonPage, IonRow, useIonAlert, IonToolbar } from "@ionic/react";
import { pushRecord, getChildData, editChildData, updateRecord } from "../functions/funcFirebase";
import { ellipsisVerticalOutline, flame, refreshOutline, addOutline } from "ionicons/icons";
import FireListEditItems from "../components/fire/FireListEditItems";
import FireAddNewItem from "../components/fire/FireAddNewItem";
import FireEditForm from "../components/fire/FireEditForm";
import WithPercent from "../components/fire/WithPercent";
import FireAddNew from "../components/fire/FireAddNew";
import FireHeader from "../components/fire/FireHeader";

import { getStockInfo, getDefaultStockAccount } from "../functions/stockFunctions";
import { setHomeCryptoDataState, setHomeStockDataState } from "../redux/actions";
import { getAssetsStats } from "../functions/assetsFunctions";
import { getCryptoInfo } from "../functions/funcCrypto";

const FirePage = () => {
  const [activeFireKey, setActiveFireKey] = useState();
  const [activeList, setActiveList] = useState();
  const [itemOpen, setItemOpen] = useState(false);
  const [addItemActive, setAddItemActive] = useState(false);
  const [fireEditActive, setFireEditActive] = useState(false);
  const [showAddFire, setShowAddFire] = useState(false);
  const [showFireItems, setShowFireItems] = useState(false);

  // SET THE ITEM NAME FOR FIREBASE OPS
  const itemName = "fire";

  const userID = useSelector((state) => state.user.uid);
  const userData = useSelector((state) => state.data);
  const remoteConfig = useSelector((state) => state.remoteConfig);
  const homeStockData = useSelector((state) => state.homeStockData);
  const homeCryptoData = useSelector((state) => state.homeCryptoData);
  const [assetsStats, setAssetsStats] = useState({ total: 0, realEstate: 0, cash: 0, other: 0 });

  const [itemsData, setItemsData] = useState([]);
  const [form, setForm] = useState({});
  const [present] = useIonAlert();
  const dispatch = useDispatch();

  const getItems = async () => {
    const resData = await getChildData(userID, itemName);
    setItemsData(resData && resData.data && Object.entries(resData.data));
  };

  const populateItem = async (fireKey, itemID) => {
    const resData = await getChildData(userID, `/${itemName}/${fireKey}/items/${itemID}`);
    setForm(resData.data);
  };

  const calTotalFireNumber = async (fireKey) => {
    let totalSpendingNumber = 0;
    let totalIncomeNumber = 0;
    const fireData = await getChildData(userID, `/${itemName}/${fireKey}/`);
    let inflationDecimal = fireData.data.inflation / 100;
    let investmentROIDecimal = fireData.data.investmentROI / 100;
    fireData.data.items &&
      Object.entries(fireData.data.items).forEach((item) => {
        let theData = item[1];
        if (theData.type === "spending") {
          // totalSpendingNumber = totalSpendingNumber + Number(theData.amount * (theData.duration || 1));
          totalSpendingNumber = totalSpendingNumber + theData.amount * ((1 - Math.pow(1 + inflationDecimal, theData.duration)) / (1 - (1 + inflationDecimal)));
        } else if (theData.type === "income") {
          if (theData.inflation === "no") totalIncomeNumber = totalIncomeNumber + Number(theData.amount * (theData.duration || 1));
          if (theData.inflation === "yes")
            totalIncomeNumber = totalIncomeNumber + theData.amount * ((1 - Math.pow(1 + investmentROIDecimal, theData.duration)) / (1 - (1 + investmentROIDecimal)));
        }
      });
    console.log("totalSpendingNumber: ", totalSpendingNumber);
    console.log("totalIncomeNumber: ", totalIncomeNumber);
    updateRecord(userID, `/${itemName}/${fireKey}`, {
      totalFireNumber: totalSpendingNumber - totalIncomeNumber,
      fireNumber: (totalSpendingNumber - totalIncomeNumber) * (Number(fireData.data.rulePercent) / 100),
      totalSpendingNumber: totalSpendingNumber,
      totalIncomeNumber: totalIncomeNumber,
    });
  };

  const switchRulePercent = (fireKey, per) => {
    updateRecord(userID, `/${itemName}/${fireKey}`, { rulePercent: per });
    getItems();
  };

  const deleteFire = (fireKey) => {
    editChildData(userID, `/${itemName}/${fireKey}/`, null);
    getItems();
  };

  const deleteItem = async (fireKey, itemID) => {
    editChildData(userID, `/${itemName}/${fireKey}/items/${itemID}`, null);
    calTotalFireNumber(fireKey);
    getItems();
  };

  const addItem = async (fireKey) => {
    pushRecord(userID, `/${itemName}/${fireKey}/items/`, form);
    setAddItemActive(false);
    calTotalFireNumber(fireKey);
    setActiveFireKey(fireKey);
    setShowFireItems(true);
    getItems();
  };

  const editItem = async (fireKey, itemID) => {
    editChildData(userID, `/${itemName}/${fireKey}/items/${itemID}`, form);
    setItemOpen(false);
    calTotalFireNumber(fireKey);
    getItems();
  };
  const handleOpen = (fireKey, itemsKey) => {
    populateItem(fireKey, itemsKey);
    setActiveList(itemsKey);
    setActiveFireKey(fireKey);
    setItemOpen(!itemOpen);
  };
  const handleFireUpdate = (fireKey, update) => {
    updateRecord(userID, `/${itemName}/${fireKey}`, update);
    getItems();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const getCrypto = async () => {
    const cryptoRawData = await getChildData(userID, "/crypto");
    const cryptoInfo = await getCryptoInfo(userID, remoteConfig, cryptoRawData.data);
    dispatch(setHomeCryptoDataState(cryptoInfo));
  };

  const getAssets = () => {
    setAssetsStats(getAssetsStats(userData.assets));
  };

  const getStock = async () => {
    const defaultStockAccount = getDefaultStockAccount(userData.accounts);
    const stockInfo = await getStockInfo(
      userID,
      remoteConfig,
      userData[`account-data${defaultStockAccount.id}`].positions,
      "IBKR",
      defaultStockAccount.data.deposits,
      userData[`account-data${defaultStockAccount.id}`].cash.endingCash
    );
    dispatch(setHomeStockDataState(stockInfo));
  };

  useEffect(() => {
    getItems();
    getCrypto();
    getStock();
    getAssets();
  }, []);

  return (
    <IonPage>
      {/* <IonToolbar mode="ios" id="cry"></IonToolbar> */}
      <IonContent>
        <div className="the-top-container">
          <div className="crypto-header">
            <IonRow>
              <IonCol size="12">
                <div className="top-title">
                  <IonIcon icon={flame} style={{ marginBottom: -3 }} />
                  <span style={{ fontWeight: 500, letterSpacing: "0.5px", fontSize: "30px" }}>F.I.</span>
                  <span style={{ fontWeight: 200, letterSpacing: "0.5px", fontSize: "30px" }}>R.E.</span>

                  <div style={{ marginTop: "1px", marginRight: "0px", float: "right" }}>
                    <IonIcon
                      icon={addOutline}
                      onClick={() => {
                        setShowAddFire(true);
                      }}
                    />
                    <IonIcon icon={refreshOutline} onClick={() => getItems()} style={{ paddingLeft: "6px" }} />
                    {/* TODO */}
                    <IonIcon icon={ellipsisVerticalOutline} onClick={() => console.log("click")} />
                    <p />
                  </div>
                </div>
              </IonCol>
            </IonRow>
            {showAddFire && <FireAddNew userID={userID} showAddFire={showAddFire} setShowAddFire={setShowAddFire} getItems={getItems} />}
          </div>

          {itemsData && itemsData.length > 0 ? (
            itemsData.map((item) => {
              let fireKey = item[0];
              let theData = item[1];
              return (
                <Fragment key={fireKey}>
                  <FireHeader
                    userID={userID}
                    getItems={getItems}
                    setShowFireItems={setShowFireItems}
                    showFireItems={showFireItems}
                    theData={theData}
                    fireEditActive={fireEditActive}
                    setFireEditActive={setFireEditActive}
                    setAddItemActive={setAddItemActive}
                    activeFireKey={activeFireKey}
                    setActiveFireKey={setActiveFireKey}
                    fireKey={fireKey}
                    handleFireUpdate={handleFireUpdate}
                  />

                  <FireEditForm
                    fireKey={fireKey}
                    activeFireKey={activeFireKey}
                    fireEditActive={fireEditActive}
                    setFireEditActive={setFireEditActive}
                    data={theData}
                    handleFireUpdate={handleFireUpdate}
                    deleteFire={deleteFire}
                  />

                  <WithPercent
                    fireKey={fireKey}
                    data={theData}
                    rulePercent={theData.rulePercent}
                    fireTotalAmount={theData.fireNumber}
                    switchRulePercent={switchRulePercent}
                    homeStockData={homeStockData}
                    homeCryptoData={homeCryptoData}
                    includeStockCashAsInvestment={userData.settings.fire.includeStockCashAsInvestment}
                    assets={assetsStats.investment && assetsStats.investment}
                  />

                  <FireAddNewItem
                    addItemActive={addItemActive}
                    activeFireKey={activeFireKey}
                    fireKey={fireKey}
                    handleChange={handleChange}
                    form={form}
                    addItem={addItem}
                    setAddItemActive={setAddItemActive}
                  />

                  <FireListEditItems
                    showFireItems={showFireItems}
                    theData={theData}
                    handleOpen={handleOpen}
                    fireKey={fireKey}
                    itemOpen={itemOpen}
                    activeFireKey={activeFireKey}
                    activeList={activeList}
                    form={form}
                    handleChange={handleChange}
                    present={present}
                    deleteItem={deleteItem}
                    editItem={editItem}
                    setItemOpen={setItemOpen}
                  />
                </Fragment>
              );
            })
          ) : (
            // ####################### NO ASSETS ADDED YET #######################
            <>
              <br />
              <br />
              <div style={{ textAlign: "center", fontSize: "16px", fontWeight: 500, color: "darkgray" }}>No FIRE Planes Added Yet</div>
              <br />
              <div style={{ justifyContent: "center", textAlign: "center", display: "flex" }}>
                <div onClick={() => setShowAddFire(true)} className="general-button" style={{ width: "200px" }}>
                  Add A Plan
                </div>
              </div>
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default FirePage;
