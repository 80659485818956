import { useState } from "react";
import { IonRow, IonCol, IonIcon, IonPopover } from "@ionic/react";
import { arrowBackOutline, swapHorizontalOutline, fingerPrintOutline, refreshOutline, ellipsisVerticalOutline } from "ionicons/icons";
import { Fragment } from "react";

import StockHeaderStats from "./StockHeaderStats";
import ProgressBar from "../general/ProgressBar";

const StockHeader = ({
  privacyMode,
  streamingMode,
  setStreamingMode,
  setPrivacyMode,
  refreshFBData,
  activeStockAccount,
  totalMktValue,
  userData,
  manualStockAccountInfo,
  stockROI,
  liveStock,
  logout,
}) => {
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [progress, setProgress] = useState(83);

  return (
    <Fragment>
      <IonPopover event={popoverState.event} isOpen={popoverState.showPopover} onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}>
        <div style={{ margin: "4px", padding: "3px", backgroundColor: "lightgray" }} onClick={() => liveStock()}>
          Refresh Data
        </div>
        <div style={{ margin: "4px", padding: "3px", backgroundColor: "lightgray" }} onClick={() => logout()}>
          Logout
        </div>
      </IonPopover>

      <div className="stocks-header">
        <IonRow>
          <IonCol size="12">
            <div className="top-title">
              <IonIcon icon={arrowBackOutline} size="14px" stocks-privacy-mode style={{ marginBottom: "-4px", marginRight: "4px" }} />
              STOCKS
              <div style={{ marginTop: "1px", marginRight: "0px", float: "right" }}>
                {privacyMode && <span className="stocks-privacy-mode">PRIVACY ON</span>}
                {/* {streamingMode && <span className="streaming-mode">STREAMING</span>} */}

                <IonIcon icon={swapHorizontalOutline} onClick={() => setStreamingMode(!streamingMode)} style={{ paddingLeft: "12px" }} />
                <IonIcon icon={fingerPrintOutline} onClick={() => setPrivacyMode(!privacyMode)} style={{ paddingLeft: "12px" }} />
                <IonIcon icon={refreshOutline} onClick={() => refreshFBData()} style={{ paddingLeft: "12px" }} />
                <IonIcon
                  icon={ellipsisVerticalOutline}
                  style={{ paddingLeft: "6px", paddingRight: "0px" }}
                  onClick={(e) => {
                    e.persist();
                    setShowPopover({ showPopover: true, event: e });
                  }}
                />
              </div>
            </div>
          </IonCol>
        </IonRow>

        <StockHeaderStats
          privacyMode={privacyMode}
          activeStockAccount={activeStockAccount}
          totalMktValue={totalMktValue}
          accountData={userData[`account-data${activeStockAccount.id}`]}
          manualStockAccountInfo={manualStockAccountInfo}
          stockROI={stockROI}
        />
      </div>
    </Fragment>
  );
};

export default StockHeader;
