import { Buffer } from "buffer";
import { IonContent, IonPage, IonRefresher, IonRefresherContent, IonGrid, IonSpinner } from "@ionic/react";
import { useState, useEffect, useCallback, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RefresherEventDetail } from "@ionic/core";
import { Route } from "react-router";

import { setDataState, setHomeCryptoDataState, setUserState } from "../redux/actions";
import { stocksSettings, tradesCount, transactionsCount } from "../settings/Settings";
import { getChildData, getData, logoutUser } from "../functions/funcFirebase";
import { getIBKRStockInfo, calcTrades } from "../functions/stockFunctions";
import { getNYSETime } from "../functions/baseFunctions";
import { getCryptoInfo } from "../functions/funcCrypto";

// import StockEvents from "../components/stocks/StockEvents";
import StockWatchListManage from "../components/stocks/StockWatchListManage";
import StockMarketSummary from "../components/stocks/StockMarketSummary";
import StockTransactions from "../components/stocks/StockTransactions";
import StockCashReport from "../components/stocks/StockCashReport.jsx";
import StockWatchList from "../components/stocks/StockWatchList.jsx";
import StockManualTable from "../components/stocks/StockManualTable";
import StockTradesStats from "../components/stocks/StockTradesStats";
import StockGroupManage from "../components/stocks/StockGroupManage";
import StockSearchBlock from "../components/stocks/StockSearchBlock";
import StocksPercentage from "../components/stocks/StocksPercentage";
import StockDividends from "../components/stocks/StockDividends";
import StockDCAManage from "../components/stocks/StockDCAManage";
import StockEarnings from "../components/stocks/StockEarnings";
import StockTable from "../components/stocks/StocksTable.jsx";
import StockAccount from "../components/stocks/StockAccount";
import StockDetails from "../components/stocks/StockDetails";
import StockHeader from "../components/stocks/StockHeader";
import StockTrades from "../components/stocks/StockTrades";
import BLSChart from "../components/stocks/BLSChart";
import NavWrap from "../components/stocks/NavWrap";
import protobuf from "protobufjs";
import "./Stocks.css";

let streamSymbols: any = [];

const Stocks: React.FC = () => {
  const dispatch = useDispatch();
  const userID = useSelector((state: any) => state.user.uid);
  const userData = useSelector((state: any) => state.data);
  const remoteConfig = useSelector((state: any) => state.remoteConfig);
  const [totals, setTotals] = useState({ totalUnrealized: 0, totalMktValue: 0, totalLoss: 0 });

  const [stockROI, setStockROI] = useState(0);
  const [privacyMode, setPrivacyMode] = useState(false);
  const [streamingMode, setStreamingMode] = useState(false);
  const [liveStockData, setLiveStockData]: any = useState([]);
  const [rawPositions, setRawPositions]: any = useState([]);
  const [liveWatchListData, setLiveWatchListData]: any = useState([]);
  const [stockEarningsData, setStockEarningsData]: any = useState({});
  const [stockDividendsData, setStockDividendsData]: any = useState({});
  const [marketSummary, setMarketSummary]: any = useState();
  const [stockSortedItem, setStockSortedItem] = useState("symbol");
  const [streamStockData, setStreamStockData]: any = useState({});
  const [streamTotalPNL, setStreamTotalPNL] = useState(0);
  const [filteredStock, setFilteredStock] = useState("");
  const [filteredGroupStock, setFilteredGroupStock]: any = useState([]);
  const [filteredData, setFilteredData]: any = useState([]);
  const [activeStock, setActiveStock] = useState("");
  const [activeGroup, setActiveGroup] = useState();
  const [activeStockAccount, setActiveStockAccount]: any = useState({});
  const [stockBlsBlock, setStockBlsBlock] = useState(false);
  const [stockNAV, setStockNAV] = useState(false);
  const [stockTradesStatistics, setStockTradesStatistics] = useState({});
  const [manualStockAccountInfo, setManualStockAccountInfo] = useState();
  const [hardRefresh, setHardRefresh] = useState(false);
  const [stockSettings, setStockSettings]: any = useState({});

  // let data = streamStockData.data;

  const logout = () => {
    logoutUser();
    dispatch(setUserState({ uid: null }));
  };

  // Refresh the Firebase Data
  const refreshFBData = async () => {
    setLiveStockData([]);
    setMarketSummary([]);

    setHardRefresh(false);
    const dataRes = await getData({ uid: userID });
    if (dataRes.status === "success") {
      dispatch(setDataState(dataRes.data));
      liveStock();
    } else {
      console.log("Firebase Refresh Error Error");
    }
  };

  const liveStock = useCallback(async () => {
    setStockNAV(false);
    setStockBlsBlock(false);
    setTotals({ totalUnrealized: 0, totalMktValue: 0, totalLoss: 0 });
    setLiveStockData([]);
    setMarketSummary([]);

    const IBKRStockInfo = await getIBKRStockInfo(
      userID,
      remoteConfig,
      userData[`account-data${activeStockAccount.id}`].positions,
      "IBKR",
      activeStockAccount.deposits,
      userData[`account-data${activeStockAccount.id}`].cash.endingCash,
      userData.stockWatchList
    );

    streamSymbols = IBKRStockInfo.symbols;
    setStreamStockData({ data: IBKRStockInfo.streamData });
    setRawPositions(userData[`account-data${activeStockAccount.id}`].positions);
    setMarketSummary(IBKRStockInfo.marketSummary);
    setLiveStockData(IBKRStockInfo.stocks);
    setLiveWatchListData(IBKRStockInfo.stockWatch);
    setStockEarningsData(IBKRStockInfo.stockEarnings);
    setStockDividendsData(IBKRStockInfo.stockDividends);

    setStreamTotalPNL(IBKRStockInfo.stats.totalPNL);
    setTotals({
      totalMktValue: IBKRStockInfo.stats.totalMktValue,
      totalUnrealized: IBKRStockInfo.stats.totalUnrealized,
      totalLoss: IBKRStockInfo.stats.totalLoss,
    });

    setStockROI(IBKRStockInfo.stats.stocksROI);
  }, [userID, remoteConfig, userData, activeStockAccount]); // Add other dependencies if needed

  const doRefresh = useCallback(
    (event: CustomEvent<RefresherEventDetail>) => {
      if (activeStockAccount.id && activeStockAccount.type === "IBKR") {
        liveStock();
      }

      setTotals({ totalUnrealized: 0, totalMktValue: 0, totalLoss: 0 });

      setTimeout(() => {
        event.detail.complete();
      }, 1000);
    },
    [activeStockAccount, liveStock]
  );

  const getCryptoAndStock4Fire = async () => {
    const cryptoRawData = await getChildData(userID, "/crypto");
    const cryptoInfo = await getCryptoInfo(userID, remoteConfig, cryptoRawData.data);
    dispatch(setHomeCryptoDataState(cryptoInfo));
  };

  const getAcc = async () => {
    userData.accounts &&
      Object.entries(userData.accounts).map((item: any) => {
        let key = item[0];
        let dato = item[1];
        dato.default &&
          setActiveStockAccount({
            id: key,
            name: dato.name,
            type: dato.type,
            deposits: dato.deposits,
            default: dato.default,
            cashBalance: dato.cashBalance,
            stockTradesMonthlyBudget: dato.stockTradesMonthlyBudget,
          });
      });
  };

  useEffect(() => {
    setStreamTotalPNL(0);
    if (streamStockData && streamStockData.data) {
      liveStockData.forEach((item: any) => {
        let last = Number(streamStockData.data[item.symbol].last);
        let change = Number(streamStockData.data[item.symbol].change);
        let changePercent = Number(streamStockData.data[item.symbol].changePercent);

        setLiveStockData((prevStockData: any) =>
          prevStockData.map((stock: any) =>
            stock.symbol === item.symbol
              ? {
                  ...stock,
                  last,
                  change,
                  changePercent,
                  // pnl,
                  pnl: change * +item.position,
                  unrealized_pnl: last * +item.position - +item.avgPrice * +item.position,
                }
              : stock
          )
        );
        // Update the Daily PnL & Live Data
        setStreamTotalPNL((streamTotalPNL) => Number(streamTotalPNL) + Number(streamStockData.data[item.symbol].change) * Number(item.position));
      });
    }
    return () => {
      setStreamTotalPNL(0);
    };
  }, [streamStockData]);

  useEffect(() => {
    setStockSettings(stocksSettings);
    getCryptoAndStock4Fire();
    getAcc();
    streamSymbols = ["^GSPC", "^DJI", "^IXIC"];
    if (activeStockAccount.id && activeStockAccount.type === "IBKR") {
      activeStockAccount.id && liveStock();
    }
  }, []);

  useEffect(() => {
    setFilteredData([]);
    if (filteredGroupStock.length > 0) {
      filteredGroupStock.map((stock: any) => {
        let filter = liveStockData.filter((item: any) => item.symbol === stock.toUpperCase());
        return setFilteredData((filteredData: any) => [...filteredData, filter[0]]);
      });
    }
  }, [filteredGroupStock]);

  useEffect(() => {
    if (activeStockAccount.id && activeStockAccount.type === "IBKR") {
      liveStock();
      setStockTradesStatistics(calcTrades(userData[`account-data${activeStockAccount.id}`].trades, activeStockAccount));
    }
  }, [activeStockAccount.id, activeGroup]);

  useEffect(() => {
    hardRefresh && refreshFBData();
  }, [hardRefresh]);

  useEffect(() => {
    const ws = new WebSocket("wss://streamer.finance.yahoo.com");

    if (streamingMode) {
      protobuf.load("./YPricingData.proto", (error, root) => {
        if (error) {
          return console.log(error);
        }

        const streamer = root?.lookupType("yaticker");

        ws.onopen = function open() {
          ws.send(JSON.stringify({ subscribe: streamSymbols }));
        };

        // Listening to the WS messages and updating the stock data
        ws.onmessage = function incoming(message) {
          // Listen for Messages and decode them
          const next: any = streamer?.decode(new Buffer(message.data, "base64"));
          // Update the streaming data
          setStreamStockData((prevData: any) => ({
            ...prevData,
            data: {
              ...prevData.data,
              [next.id]: {
                last: next.price,
                change: next.change,
                changePercent: next.changePercent,
              },
            },
          }));
        };
      });
    }

    if (!streamingMode) {
      if (ws.readyState === WebSocket.OPEN) {
        ws.close(1000);
      }
    }

    return () => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.close(1000);
      }
    };
  }, [streamingMode]);

  return (
    <Fragment>
      <IonPage>
        {/* <IonToolbar mode="ios" id="sto"></IonToolbar> */}

        <Route exact path="/stocks/dca" render={() => <StockDCAManage />} />
        <Route exact path="/stocks/watchlist" render={() => <StockWatchListManage />} />
        <Route exact path="/stocks/groups" render={() => <StockGroupManage />} />
        <Route exact path="/stocks/details" render={() => <StockDetails />} />
        <Route
          exact
          path="/stocks/transactions"
          render={() => (
            <StockTransactions
              data={activeStockAccount.id && userData[`account-data${activeStockAccount.id}`] && userData[`account-data${activeStockAccount.id}`].transactions}
              filteredStock={filteredStock}
              count={transactionsCount}
            />
          )}
        />
        <Route
          exact
          path="/stocks/trades"
          render={() => (
            <StockTrades
              data={activeStockAccount.id && userData[`account-data${activeStockAccount.id}`] && userData[`account-data${activeStockAccount.id}`].trades}
              filteredStock={filteredStock}
              count={tradesCount}
              title={"Trades"}
              privacyMode={privacyMode}
            />
          )}
        />

        <IonContent>
          <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>

          <IonGrid className="ion-no-padding">
            <div className="the-top-container">
              <StockHeader
                privacyMode={privacyMode}
                streamingMode={streamingMode}
                setStreamingMode={setStreamingMode}
                setPrivacyMode={setPrivacyMode}
                refreshFBData={refreshFBData}
                activeStockAccount={activeStockAccount}
                totalMktValue={totals.totalMktValue}
                userData={userData}
                manualStockAccountInfo={manualStockAccountInfo}
                stockROI={stockROI}
                liveStock={liveStock}
                logout={logout}
              />

              <div className="stock-nyse-time">NYSE: {getNYSETime()}</div>
              {/* <StockEvents events={remoteConfig.events} /> */}

              <StockAccount accounts={userData.accounts} activeStockAccount={activeStockAccount} setActiveStockAccount={setActiveStockAccount} />

              {stockSettings.cashReport && (
                <StockCashReport
                  privacyMode={privacyMode}
                  accountID={activeStockAccount.id}
                  accountCashData={activeStockAccount.id && userData[`account-data${activeStockAccount.id}`] && userData[`account-data${activeStockAccount.id}`].cash}
                  totalPNL={streamTotalPNL}
                  streamTotalPNL={streamTotalPNL}
                  totalUnrealized={totals.totalUnrealized}
                  activeStockAccount={activeStockAccount}
                  streamingMode={streamingMode}
                  totalMktValue={totals.totalMktValue}
                  deposits={activeStockAccount && activeStockAccount.deposits}
                  totalLoss={totals.totalLoss}
                />
              )}

              <StockSearchBlock
                setStockBlsBlock={setStockBlsBlock}
                stockBlsBlock={stockBlsBlock}
                filteredStock={filteredStock}
                setFilteredStock={setFilteredStock}
                setStockNAV={setStockNAV}
                stockNAV={stockNAV}
                setStockSettings={setStockSettings}
                stockSettings={stockSettings}
              />

              {stockNAV && (
                <NavWrap
                  data={userData[`account-data${activeStockAccount.id}`].NAV}
                  color={"#40739e"}
                  type={"stock"}
                  deposits={activeStockAccount && activeStockAccount.deposits}
                />
              )}

              {stockBlsBlock && <BLSChart token={userID} />}
              {stockSettings.indices && <StockMarketSummary marketSummary={marketSummary} token={userID} remoteConfig={remoteConfig} />}
              {stockSettings.watchList && <StockWatchList liveWatchListData={liveWatchListData} setFilteredStock={setFilteredStock} />}
              {stockSettings.stocksPercentage && <StocksPercentage data={rawPositions} topN="5" />}

              <div className="stock-ern-div-wrapper">
                {stockSettings.earnPanel && <StockEarnings stockEarningsData={stockEarningsData} setFilteredStock={setFilteredStock} />}
                {stockSettings.divPanel && <StockDividends stockDividendsData={stockDividendsData} setFilteredStock={setFilteredStock} />}
              </div>
              {stockSettings.trades && <StockTradesStats data={stockTradesStatistics} privacyMode={privacyMode} />}

              {liveStockData.length > 0 ? (
                <StockTable
                  userID={userID}
                  liveStockData={filteredData.length > 0 ? filteredData : liveStockData}
                  privacyMode={privacyMode}
                  stockSortedItem={stockSortedItem}
                  setStockSortedItem={setStockSortedItem}
                  setFilteredStock={setFilteredStock}
                  filteredStock={filteredStock}
                  stockSettings={stockSettings}
                  setStockSettings={setStockSettings}
                  remoteConfig={remoteConfig}
                  trades={activeStockAccount.type === "IBKR" && userData[`account-data${activeStockAccount.id}`].trades}
                  activeStockAccount={activeStockAccount}
                  setFilteredGroupStock={setFilteredGroupStock}
                  stockGroups={userData.stockGroup && userData.stockGroup}
                  activeStock={activeStock}
                  setActiveStock={setActiveStock}
                  setActiveGroup={setActiveGroup}
                  setHardRefresh={setHardRefresh}
                />
              ) : (
                <div style={{ textAlign: "center", marginTop: "30px" }}>
                  <IonSpinner />
                </div>
              )}

              {activeStockAccount.id && activeStockAccount.type !== "IBKR" && (
                <StockManualTable
                  userID={userID}
                  activeStockAccount={activeStockAccount}
                  remoteConfig={remoteConfig}
                  stockSettings={stockSettings}
                  stockSortedItem={stockSortedItem}
                  privacyMode={privacyMode}
                  setManualStockAccountInfo={setManualStockAccountInfo}
                />
              )}
            </div>
          </IonGrid>
        </IonContent>
      </IonPage>
    </Fragment>
  );
};

export default Stocks;
